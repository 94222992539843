/*
Redux combined reducers

Author: Zsolt Husz
Date: 31/01/2020
*/

import { combineReducers } from 'redux';

import user from './user';
import status from './status';
import filters from './filters';

const rootReducer = combineReducers({
  user,
  filters,
  status,
});

export default rootReducer;
