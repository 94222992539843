import { UserUpdate, UserRemove } from '../constants';
import { permissionLevels } from '../../libs/config';
import {UserReduxType} from '../../libs/types'

const logedOutState:UserReduxType = {
  username: '',
  id: '',
  accessToken: '',
  refreshToken: '',
  loggedIn: false,
  isAdmin: false,
  permissionLevel: 0,
  profile: '',
};

export default function userReducer(state = logedOutState, action: any) {
  switch (action.type) {
    case UserUpdate:
       const s = {
        ...state,
        ...action.user, 
      };
      s.isAdmin = s.permissionLevel === permissionLevels.ADMIN;
      return s;
    case UserRemove:
      return {
        ...logedOutState,
      };

    default:
      return state;
  }
}
