
import { SetLoading } from '../constants';
import { SetError } from '../constants';

export function setLoading(loading: boolean) {
  return {
    type: SetLoading,
    loading,
  };
}

export function setError(error: string) {
  return {
    type: SetError,
    error,
  };
}
export function clearError() {
  return {
    type: SetError,
    error: '',
  };
}