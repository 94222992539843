import { SetLoading } from '../constants';
import { SetError } from '../constants';

import {StatusReduxType} from '../../libs/types'

const initialState:StatusReduxType = {
  loading:false,
  error: '',
};

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case SetLoading:
      return { ...state, loading: action.loading};

      case SetError:
        return { ...state, error: action.error};
    
    default:
      return state;
  }
}
