import React, { Suspense } from "react";

import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "../redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import Navigation from "./Navigation";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//import Loading from "../containers/Loading";

//import Main from "./Main";
const Main = React.lazy(() => import("./Main"));
const Terms = React.lazy(() => import("./Terms"));
const Privacy = React.lazy(() => import("./Privacy"));
const Reopens = React.lazy(() => import("./Reopens"));

const Cookie = React.lazy(() => import("./Cookie"));
const SignIn = React.lazy(() => import("./Auth/SignInSide"));
const Reminder = React.lazy(() => import("./Auth/Reminder"));
const SignUp = React.lazy(() => import("./Auth/SignUp"));

const Info = React.lazy(() => import("./Info"));
const ListUsers = React.lazy(() => import("./Users/ListUsers"));
const Profile = React.lazy(() => import("./Users/Profile"));
//const DetailsView = React.lazy(() => import("./DetailsView"));

/*
import Privacy from "./Privacy";
import Terms from "./Terms";
import Cookie from "./Cookie";
import Reopens from "./Reopens";

import SignIn from "./Auth/SignInSide";
import Reminder from "./Auth/Reminder";
import  from "./Auth/SignUp";
import Info from "./Info";
//import EditUser from "./Users/EditUser";
import ListUsers from "./Users/ListUsers";
import Profile from "./Users/profile";
import DetailsView from "./DetailsView";
import Main from "./Main";

*/

function App() {



  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="App">
            <Router>
              <Navigation />
              <Suspense fallback={null}>
                <Switch>
                  <Route path="/(privacy|privacy.html|cookies.html)">
                    <Privacy />
                  </Route>
                  <Route path="/(terms|terms.html)">
                    <Terms />
                  </Route>
                  <Route path="/main">
                    <Main />
                  </Route>
                  <Route path="/signin">
                    <SignIn />
                  </Route>
                  <Route path="/reminder">
                    <Reminder />
                  </Route>
                  <Route path="/signup">
                    <SignUp />
                  </Route>
                  <Route path="/info/:id">
                    <Info />
                  </Route>

                  <Route path="/users">
                    <ListUsers />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <Route path="/restart">
                    <Reopens />
                  </Route>
                 {/*  <Route path="/test">
                    <div className="DelelteTempStyle">
                      <DetailsView
                        onClose={() => {}}
                        onCreate={() => {}}
                        onEdit={() => {}}
                        isOpen={true}
                        data={{
                          address: "  Abbey St",
                          city: "Melrose",
                          closed: true,
                          country: "SCT",
                          covid: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. I
          Curabitur quis tempor turpis, quis laoreet velit. Duis dignissim libero nec massa bibendum tincidunt. Integer nunc ex, cursus ac massa vitae, convallis venenatis purus. Fusce tincidunt diam tempor sapien feugiat porttitor. Sed eu justo dolor. Nam quis placerat libero. Suspendisse interdum lacus rutrum vulputate malesuada. Vivamus libero ante, rhoncus quis ultricies sed, consectetur nec nibh. Mauris tempor tristique nulla, molestie malesuada quam posuere a. Praesent commodo massa est, consectetur imperdiet odio ullamcorper non. Duis id aliquet ante. Mauris mattis diam dignissim tortor auctor, eu convallis magna venenatis. Morbi eleifend est et est hendrerit, eu ornare elit dignissim. Fusce rhoncus sed lorem eget elementum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse sit amet vehicula eros, imperdiet tincidunt diam.
        . Cras metus`,
                          created: "1592849963151",
                          dest: "Priorwood Garden",
                          lat: 55.598396,
                          lng: -2.718743,
                          opens: 1591920000000,
                          org: "5ef0f1d72a370",

                          pictures: [
                            "https://s3-eu-west-1.amazonaws.com/ws-nts/Production/assets/images/key/main-st-abbs-cg-1.jpg?mtime=20200302110645",
                          ],
                          postcode: "TD6 9PX",
                          type: "attraction",
                          bookReq: true,
                          bookUrl: "https://deblur.co.uk",
                          phone: "07804083434",
                          webpage: "https://deblur.co.uk",
                          when: `Good to visit anytime Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consectetur est neque, vel pharetra magna accumsan eu. Quisque fringilla pulvinar porttitor. Nunc sit amet velit sit amet lectus consequat maximus sed in enim. Donec maximus magna ut turpis luctus, ut maximus quam porttitor. Vestibulum fermentum lectus risus, vitae pulvinar turpis porttitor sit amet. Etiam congue eros et neque auctor laoreet. Donec non sagittis nulla. Mauris gravida mauris id nisi pharetra lacinia sed in dolor. Nullam accumsan ultricies augue, nec consequat nisl. Etiam non risus velit. 
           
          Sed magna leo, ultricies a volutpat in, p`,
                        }}
                        date={Date.now()}
                      />
                    </div>
                  </Route>*/}
                  <Route path="/">
                  <Main />
                  </Route>
                </Switch>
              </Suspense>
            </Router>
          </div>
          <Suspense fallback={<div />}>
            <Cookie />
          </Suspense>
        </MuiPickersUtilsProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
/*
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
  <Route path="/users/edit-user">
                <EditUser />
              </Route>


  */

/*




3. Search name
1. Google analytics tracking

9. Upload pictures
10. Import HSC
  */
