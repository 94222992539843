import React from "react";
import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";
//import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootReduxType } from "../libs/types";

import { logoutUser } from "../redux/actions/users";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    userSelect: "none",
    backgroundColor: "#ffec7d",
    color: "#002060",
  },
  toolbar: {
    // flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
    padding: "10px",
    fontSize: "xx-large",
    fontFamily: "'Nunito', sans-serif",
    fontWeight: "bolder",
    // line-height: 1.6;
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: "#002060",
    fontSize: "large",
    "&:link": {
      color: "#002151",
    },
    "&:visited": {
      color: "#002151",
    },
    "&:hover": {
      color: "#002151",
      textDecoration: "none",
      borderBottom: "3px solid #002151",
      paddingBottom: "3px",
    },
  },
  linkSelected: {
    margin: theme.spacing(1, 1.5),
    color: "#002060",
    fontSize: "large",
    fontWeight: "bold",
    "&:link": {
      color: "#002151",
      //textDecoration: 'underline',
      borderBottom: "3px solid #002151",
      paddingBottom: "3px",
    },
    "&:visited": {
      color: "#002151",
      //textDecoration: 'underline',
      borderBottom: "3px solid #002151",
      paddingBottom: "3px",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },

  imquest: {
    maxWidth: "75%",

    maxHeight: "50px",
    height: "auto",
    width: "auto",
  },
  "@media only screen and (max-width: 768px)": {
    imquest: {
      display: "none",
    },
  },
  imq: {
    maxWidth: "50%",
    maxHeight: "50px",
    height: "auto",
    width: "auto",
  },
  "@media only screen and (min-width: 768px)": {
    imq: {
      display: "none",
    },
  },
  imageContainer: {
    flexGrow: 1,
  },
}));

const Img = (props: any) => {
  const classes = useStyles();

  // const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <div className={classes.imageContainer}>
      <img
        className={classes.imquest}
        src={require("../assets/images/qn400.png")}
        alt="QuestNative"
      />
      <img
        className={classes.imq}
        src={require("../assets/images/q400.png")}
        alt="QuestNative"
      />
    </div>
  );
};

const reverseRouter = [
  { path: "/restart", id: 1 },
  { path: "/main", id: 2 },
  { path: "/", id: 1 },
  { path: "/profile", id: 3 },
  { path: "/signin", id: 4 },
];

export default function Navigation() {
  const classes = useStyles();
  const loggedIn = useSelector((state: RootReduxType) => state.user.loggedIn);
  const dispatch = useDispatch();
  const location = useLocation();

  const current = reverseRouter.find((i) => i.path === location.pathname)?.id;

  const logout = () => {
    dispatch(logoutUser());
  };
  /*
  if (!loggedIn)
  return (

           <div className="row cx">
          <article className="col-xs-12 col-md-12 text-center">
            <h1>Re-start!
              </h1>
          </article>
        </div>
        
    <div className="row ">
    <article className="col-xs-12 col-md-12 text-center">
      <p className="reopens-text">
        <b> Explore Scotland and Britain after the lockdown!</b>
      </p>
          <p className="reopens-text">
                Search for venues that are open.
            </p>
    </article>
  </div>
  



);*/

  React.useEffect(() => {
    ReactGA.send(["pageview", location.pathname]);
    //console.log("Page ", location.pathname);
  }, [location]);

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Img />
        <nav>
          {/*  <Link
            href="/restart"
            className={current === 1 ? classes.linkSelected : classes.link}
          >
            Restart!
          </Link>
          <Link
            href="/main"
            className={current === 2 ? classes.linkSelected : classes.link}
          >
            Discover
          </Link>*/}
          {/* loggedIn ? (
            <Link
              variant="button"
              color="textPrimary"
              href="/profile"
              className={classes.link}
            >
              My venues
            </Link>
          ) : null */}
          {loggedIn ? (
            <Link
              //     variant="button"
              href="/profile"
              className={current === 3 ? classes.linkSelected : classes.link}
            >
              Profile
            </Link>
          ) : null}
          {loggedIn ? (
            <Link onClick={logout} href="/restart" className={classes.link}>
              Logout
            </Link>
          ) : (null
            /*        <Link
                       href="/signin"
                       className={current === 4 ? classes.linkSelected : classes.link}
                     >
                       Venues
            </Link>*/
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
}
