import {
  SetFilter,
  SetFilterReset,
  SetFilterSearch,
  SetFilterShowOpening,
  SetFilterShowClosed,
  SetFilterTypes,
  SetFilterDate,
} from "../constants";
import { FilterReduxType, VenueType } from "../../libs/types";

export function setFilterReset() {
  return {
    type: SetFilterReset,
  };
}

export function setFilterAction(filter: FilterReduxType) {
  return {
    type: SetFilter,
    filter,
  };
}

export function setFilterDateAction(date: number) {
  return {
    type: SetFilterDate,
    date,
  };
}

export function setFilterTypesAction(vtype: VenueType, on: boolean) {
  return {
    type: SetFilterTypes,
    vtype,
    on,
  };
}
export function setFilterShowClosedAction(showClosed: boolean) {
  return {
    type: SetFilterShowClosed,
    showClosed,
  };
}
export function setFilterShowOpeningAction(showOpening: boolean) {
  return {
    type: SetFilterShowOpening,
    showOpening,
  };
}
export function setFilterSearchAction(search: string) {
  return {
    type: SetFilterSearch,
    search,
  };
}
