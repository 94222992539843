import {
  SetFilter,
  SetFilterSearch,
  SetFilterShowOpening,
  SetFilterShowClosed,
  SetFilterTypes,
  SetFilterDate,
  SetFilterReset,
} from "../constants";

import { FilterReduxType, VenueType } from "../../libs/types";
const initialState: FilterReduxType = {
  date: Date.now(),
  types: {
    attraction: true,
    accom: true,
    fooddrink: true,
    information: true,
    eoho: true,
    other: true,
  },
  showClosed: true,
  showOpening: true,
  search: "",
};

export default function filterReducer(state = initialState, action: any) {
  switch (action.type) {
    case SetFilter:
      return { ...state, ...action.filter };
    case SetFilterDate:
      return { ...state, date: action.date };
    case SetFilterTypes:
      return {
        ...state,
        types: { ...state.types, [action.vtype as VenueType]: action.on },
      };
    case SetFilterShowClosed:
      return { ...state, showClosed: action.showClosed };
    case SetFilterShowOpening:
      return { ...state, showOpening: action.showOpening };
    case SetFilterSearch:
      return { ...state, search: action.search };
    case SetFilterReset:
      return initialState;
    default:
      return state;
  }
}
