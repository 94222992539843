import { createStore , applyMiddleware} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import reducer from './reducers'
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';

import {setFilterReset} from './actions/filters';

const persistConfig = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(persistedReducer,applyMiddleware(thunk));
export const persistor = persistStore(store);

if (!store.getState().filters) {
  store.dispatch(setFilterReset());
}