// Redux Action Constants

export const UserUpdate = 'USERUPDATE';
export const UserRemove = 'USERREMOVE';
export const SetFilter = 'SETFILTER';

export const SetFilterSearch = 'SETFILTER1';
export const SetFilterShowOpening = 'SETFILTER2';
export const SetFilterShowClosed = 'SETFILTER3';
export const SetFilterTypes = 'SETFILTER4';
export const SetFilterDate = 'SETFILTER5';
export const SetFilterReset = 'SETFILTERRESET';



export const SetLoading = 'SETLOADING';
export const SetError = 'SETERROR';