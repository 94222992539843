import { UserUpdate } from '../constants';
import { UserRemove } from '../constants';

import { setLoading, setError, clearError } from './status';

import { server } from '../../libs/network';

//import { validateLogin, getUser } from '../../libs/networks';

//import { permissionLevels } from '../../config';

export function updateUser(user: any) {
  return {
    type: UserUpdate,
    user,
  };
}


export function loggedIn(user: any) {
  return {
    type: UserUpdate,
    user: {
      ...user,
      loggedIn: true,
      
      // isAdmin: user.permissionLevel === permissionLevels.ADMIN,
      // isRelator: user.permissionLevel === permissionLevels.relator,
    }
  };
}


export function fetchUserDetails(user: any) {
  return (dispatch: any, getState: any) => {
    try {
      if (user.loggedIn) {
        //dispatch(verifyPending());
        /* getUser(user, user.id).then((result) => {
             dispatch(loggedIn({ ...result, ...user }));
         })*/
      }
    }
    catch (error) {
      console.log('fetchUserDetails error:', error);
      dispatch(logoutUser());
    }
  }
}


export function logoutUser() {
  return {
    type: UserRemove,
  };
}

export function validateLoginAction() {

  return (dispatch: any, getState: any) => {
    const user = getState().user;
    //console.log('Checking validity', user);
    if (user.loggedIn) {
      //dispatch(verifyPending());
      /*     validateLogin(user).then((result) => {
             dispatch(loggedIn(result));
           }).catch((error: any) => {
             if (error === 'Unathorised')
               dispatch(logoutUser());
           });*/
    }
  }
}


export const login = (username: string, password: string, remember: boolean) => {
  return (dispatch: any, getState: any) => {
    dispatch(setLoading(true));
    dispatch(clearError());
    // dispatch(fetchAptPending());


    const data = {
      username,
      password,
    };
    const options = {
      'method': 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(server + "auth", options)
      .then(response => response.json())
      .then(result => {
        dispatch(setLoading(false));
        if (result.errors) {
          dispatch(setError(result.errors));
          return;
        }

        dispatch(loggedIn({
          username,
          ...result,

        }))
      })
      .catch((err: any) => {
        //   localStorage.setItem(userId, value.toString());
        //const symp = localStorage.getItem('userdata');
      })



    //  let response: any = await fetch(server + "auth", options);
    /*
      await handleError(response, "Server error.")
      let result = await response.json();
    
      dispatch(loggedIn({
        username,
        ...result,
        
      }));
    
        /*  getApartments(user, filters).then((result) => {
          dispatch(fetchAptSuccess(result));
          return result;
        })
          .catch(error => {
            dispatch(fetchAptError(error));
          })*/
  }
}



export const loginWithToken = (token: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setLoading(true));
    dispatch(clearError());
    // dispatch(fetchAptPending());

console.log('---++++++++--Preops login', token);
    const data = {
      token,
    };
    const options = {
      'method': 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(server + "auth/token", options)
      .then(response => response.json())
      .then(result => {
        dispatch(setLoading(false));
        if (result.errors) {
          dispatch(setError(result.errors));
          return;
        }

        dispatch(loggedIn({
      //   username,
          ...result,

        }))
      })
      .catch((err: any) => {
        //   localStorage.setItem(userId, value.toString());
        //const symp = localStorage.getItem('userdata');
      })
  }
}