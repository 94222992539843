import { store } from "../redux/Store";
import { UploadableImage } from "./types";
//import {data as eo} from '../assets/eo'
let DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
if (DEVELOPMENT) {
  console.log("*********Development enviromnent");
} else {
  ///  console.log("Production enviromnent");
}

export const server = DEVELOPMENT
  ? "http://192.168.0.33:3700/"
  : "https://api.questnative.com:35111/";

export const handleError = async (response: any, errorText: String) => {
  if (!response.ok) {
    let error = errorText;
    try {
      response = await response.json(); // nneded to

      //  console.log('***',response.errors);

      if (response.errors) error = response.errors;
      else error = response.statusText;
      /*
      if (response) {
      console.log('Error',response)
      
      //const r = await response.json();
     // error = r.errors;
    //TODO!!!!
    } else     if (response.statusText) {

      error = response.statusText;
    
    }*/
    } catch (err) {
      console.log("No Json in error:", err);
      //throw ( new Error('Unknow server error'));
    }
    throw new Error(error.toString());
  }
};

export const createUser = async (user: any) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  let response: any = await fetch(server + "users", options);
  await handleError(response, "Create user.");
  return;
};

export const emailReminder = async (userId: string) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userId }),
  };

  let response: any = await fetch(server + "reminder", options);
  await handleError(response, "Email reminder error.");
  return;
};

export const updateUser = async (user: any) => {
  const state = store.getState();
  const authToken = state.user.accessToken;
  const userId = state.user.id;
  let response: any;

  //update existing user
  const options = {
    method: "PATCH",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };
  response = await fetch(server + "users/" + userId, options);
  await handleError(response, "Error pathch user");
  return;
};

export const updateAnyUser = async (userId: string, user: any) => {
  const state = store.getState();
  const authToken = state.user.accessToken;

  let response: any;

  //update existing user
  const options = {
    method: "PATCH",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };
  response = await fetch(server + "users/" + userId, options);

  await handleError(response, "Error pathch user");
  return;
};

export const getUsers = async () => {
  const state = store.getState();
  const authToken = state.user.accessToken;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };

  let response: any = await fetch(server + "users", options);

  await handleError(response, "Error getting users");

  let result = await response.json();
  return result;
};

export const getUser = async (id?: string) => {
  const state = store.getState();
  const authToken = state.user.accessToken;
  const userID = id ? id : state.user.id;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };

  let response: any = await fetch(server + "users/" + userID, options);
  await handleError(response, "Error getting user.");

  let result = await response.json();
  return result;
};

export const getProfile = async (id?: string) => {
  const state = store.getState();
  const authToken = state.user.accessToken;
  const profileID = id ? id : state.user.profile;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };

  let response: any = await fetch(server + "profiles/" + profileID, options);
  await handleError(response, "Error getting profile.");

  let result = await response.json();
  return result;
};

export const updateProfile = async (profile: any) => {
  const state = store.getState();
  const authToken = state.user.accessToken;

  let response: any;
  const options = {
    method: "PATCH",
    body: JSON.stringify(profile),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };
  if (state.user.isAdmin)
    response = await fetch(server + "profiles/" + profile.id, options);
  else response = await fetch(server + "profiles", options);

  await handleError(response, "Error pathch profile");
  return;
};

export const createVenue = async (item: any, images: UploadableImage[]) => {
  const state = store.getState();
  const authToken = state.user.accessToken;

  let pictures: string[] = [];
  let counter = 0;
  let formData = new FormData();
  images.forEach((i) => {
    if (i.file) {
      formData.append("newpictures[]", i.file);

      pictures.push(counter.toString());
      counter++;
    } else {
      pictures.push(i.src);
    }
  });
  const data = { ...item, pictures: pictures };
  formData.append("data", JSON.stringify(data));

  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: "Bearer " + authToken,
    },
  };

  let response: any = await fetch(server + "items", options);
  await handleError(response, "Error creating item.");

  let result = await response.json();
  return result;
};

export const updateVenue = async (item: any, images: UploadableImage[]) => {
  const state = store.getState();
  const authToken = state.user.accessToken;
  const id = item._id;

  let pictures: string[] = [];
  let counter = 0;
  let formData = new FormData();
  images.forEach((i) => {
    if (i.file) {
      formData.append("newpictures[]", i.file);

      pictures.push(counter.toString());
      counter++;
    } else {
      pictures.push(i.src);
    }
  });
  const data = { ...item, pictures: pictures };
  formData.append("data", JSON.stringify(data));

  const options = {
    method: "PATCH",
    body: formData,
    headers: {
      //   'Content-Type': 'application/json',
      Authorization: "Bearer " + authToken,
    },
  };
  let response: any = await fetch(server + "items/" + id, options);
  await handleError(response, "Error creating item.");
  return;
};

export const deleteVenue = async (id: string) => {
  const state = store.getState();
  const authToken = state.user.accessToken;

  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };

  let response: any = await fetch(server + "items/" + id, options);
  await handleError(response, "Error deleting item.");
  return;
};
/*

 const getItemsEO = async () => {
  // const state = store.getState();
  // const authToken = state.user.accessToken;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  let response: any = await fetch("/assets/eo4.js", options);
  await handleError(response, "Error getting EOHO items.");
  let result = await response.json();
  return result.d;
};
*/

export const getItems = async (
  filter: any /*order: string, orderBy: string, page: number, limit: number*/
) => {
  const state = store.getState();
  const authToken = state.user.accessToken;
  //TODO: check if needs to tranmit userID
  const options = {
    method: "POST",
    body: JSON.stringify({ ...filter, userId: state.user.id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  };
  const params = "";
  /*'?' +
    'limit=' + limit +
    '&page=' + page +
    '&order=' + order +
    '&orderBy=' + orderBy;
*/

  let response: any = await fetch(server + "items/filter" + params, options);
  await handleError(response, "Error getting items.");

  let result = await response.json();

  //const eodata = await getItemsEO();
  return {items: [...result.items], count: result.count};
  //return {items: [...result.items, ...eodata], count: result.count+ eodata.length};
};

export const getItem = async (id: string) => {
  // const state = store.getState();
  // const authToken = state.user.accessToken;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //   'Authorization': 'Bearer ' + authToken,
    },
  };

  let response: any = await fetch(server + "items/" + id, options);
  await handleError(response, "Error getting item.");

  let result = await response.json();
  return result;
};
